import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { App } from '@capacitor/app';
import { Platform } from '@ionic/angular';
import { PluginListenerHandle } from '@capacitor/core';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AppStateChangeService implements OnDestroy {
  private appStateChangeSubject: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public appStateChange$: Observable<boolean> = this.appStateChangeSubject.asObservable();
  private resumeSubject: BehaviorSubject<true> = new BehaviorSubject(true);
  public resume$: Observable<true> = this.resumeSubject.asObservable();
  private appStateChangeListener: PluginListenerHandle;
  private destroySubject: Subject<void> = new Subject<void>();

  constructor(
    private ngZone: NgZone,
    private platform: Platform,
  ) {
    this.subscribeToAppStateEvents().then();
  }

  async ngOnDestroy(): Promise<void> {
    this.destroySubject.next();
    this.destroySubject.complete();
    await this.appStateChangeListener.remove();
  }

  private async subscribeToAppStateEvents(): Promise<void> {
    // Срабатывает только при возобновлении приложения из фона.
    // Специфичен для платформы Cordova.
    this.platform.resume.pipe(
      takeUntil(this.destroySubject),
    ).subscribe(async (): Promise<void> => {
      await this.ngZone.run(async (): Promise<void> => {
        // console.log('\x1b[95m' + 'App resume is: ' + true + '\x1b[0m');
        this.resumeSubject.next(true);
      });
    });

    // Работает с любыми изменениями состояния приложения (активное или неактивное)
    // Является более универсальным и поддерживает платформы iOS и Android через Capacitor.

    // Variant 1 - addListener
    this.appStateChangeListener = await App.addListener('appStateChange', async ({isActive}): Promise<void> => {
      await this.ngZone.run(async (): Promise<void> => {
        // console.log('\x1b[95m' + 'App state is active: ' + isActive + '\x1b[0m');
        this.appStateChangeSubject.next(isActive);
      });
    });

    // Variant 2 - Observable
    // const appStateChangeEvent: Observable<{ isActive: boolean }> = fromEventPattern(
    //   async (handler: NodeEventHandler): Promise<PluginListenerHandle> => await App.addListener('appStateChange', handler),
    //   async (handler: any): Promise<void> => await handler.remove(),
    // );
    //
    // appStateChangeEvent.pipe(
    //   takeUntil(this.destroySubject),
    // ).subscribe(async ({isActive}): Promise<void> => {
    //   await this.ngZone.run(async (): Promise<void> => {
    //     console.log('\x1b[95m' + 'App state is active: ' + isActive + '\x1b[0m');
    //     this.appStateChangeSubject.next(isActive);
    //   });
    // });
  }
}
