import { AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { loadStripe } from '@stripe/stripe-js/pure';
import {
  PaymentIntentResult,
  Stripe,
  StripeElements,
  StripeElementsOptions,
  StripePaymentElement
} from '@stripe/stripe-js';
import { ExtendedClient } from '../../api-clients/pyjam/extended-client';
import { ToastService } from '../../services/toast.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { LoadingService } from '../../services/loading.service';
import { NavigationService } from '../../services/navigation.service';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';
// import {StripeElementLocale} from "@stripe/stripe-js/types/stripe-js/elements-group";
// import { loadScript } from "@paypal/paypal-js";
// import { environment } from 'src/environments/environment';

declare var paypal: any;

class PaymentVm { // TODO: move to separate file
  clientSecret: string;
  amount: string;
  currency: string;
  id: string;
  gateway: string;
}

@Component({
  selector: 'app-payment-input',
  templateUrl: './payment-input.component.html',
  styleUrls: ['./payment-input.component.scss'],
})
export class PaymentInputComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() public paymentIntentType: number;
  @Input() public tipAmount: number;
  public cardErrors = null;
  public replyOrExtraWorkId: number;
  public pkCode = null;
  public vm: PaymentVm = new PaymentVm();
  private stripe: Stripe;
  private elements: StripeElements;
  private paymentElement: StripePaymentElement;
  private cardFields: any;

  constructor(
    private extendedClient: ExtendedClient,
    private toastService: ToastService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private loadingService: LoadingService,
    private navigationService: NavigationService,
    private translate: TranslateService,
    private cdr: ChangeDetectorRef,
  ) {
  }

  ngOnDestroy(): void {
    try {
      this.paymentElement.destroy();
    } catch (error) {
      console.error(error);
    }
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(async (params: Params): Promise<void> => {
      if (params && params.id) {
        if (+params.id) {
          this.replyOrExtraWorkId = +params.id;
          await this.loadingService.start();

          await this.loadSecretCode();

          await this.loadingService.stop();
        } else {
          this.router.navigate(['../']).then();
          return;
        }
      }
    });
  }

  ngAfterViewInit(): void {
    if (this.vm.gateway) {
      if (this.vm.gateway == 'paypal') {
        this.createPaypalForm();
      }
    } else {
      setTimeout((): void => {
        this.cdr.detectChanges();
      }, 1000);
    }
  }

  public async createStripeForm(): Promise<void> {
    try {
      await this.loadingService.start();

      this.stripe = await loadStripe(this.pkCode);
      let stripeElementsOptions: StripeElementsOptions = {
        clientSecret: this.vm.clientSecret,
        appearance: {
          theme: 'night',
        },
        locale: 'en'
      };
      this.elements = this.stripe.elements(stripeElementsOptions);
      this.paymentElement = this.elements.create('payment');
      this.paymentElement.mount('#card-element');
    } catch (error) {
      console.error(error);
    } finally {
      await this.loadingService.stop();
    }
  }

  public async loadSecretCode(): Promise<any> {
    try {
      await this.loadingService.start();

      const data = await firstValueFrom(this.extendedClient.paymentIntent(
        this.replyOrExtraWorkId, this.paymentIntentType,
        this.tipAmount ? this.tipAmount : undefined,
        undefined,
        undefined
      ));
      console.log(data);

      let payment: PaymentVm = new PaymentVm();

      switch (data.gateway) {
        case 'stripe':
          payment.amount = data.amount.toString();
          payment.clientSecret = data.client_secret;
          payment.currency = data.currency;
          payment.gateway = data.gateway;
          this.vm = payment;
          break;
        case 'paypal':
          payment.id = data.id;
          payment.gateway = data.gateway;
          payment.amount = data.amount.toString();
          payment.currency = data.currency;
          this.vm = payment;
          break;
        default:
          await this.toastService.error(this.translate.instant('reply.payment.undefinedGateway'));
          break;
      }
    } catch (error) {
      let parsedException = JSON.parse(error.response);
      let message = parsedException.message ?? parsedException.error;
      await this.toastService.error(message);
    } finally {
      await this.loadingService.stop();
    }
  }

  public async sendCardToken(): Promise<void> {
    switch (this.vm.gateway) {
      case 'stripe':
        try {
          await this.loadingService.start();

          const result: PaymentIntentResult = await this.stripe.confirmPayment({
            elements: this.elements,
            redirect: 'if_required'
          });

          if (result.error) {
            this.cardErrors = result.error.message;
          } else {
            switch (this.paymentIntentType) {
              case 1:
                await this.approveReply();
                break;
              case 4:
                try {
                  await this.loadingService.start();

                  await firstValueFrom(this.extendedClient.replyGet(this.replyOrExtraWorkId, false));
                  await this.navigationService.goBack();
                } catch (error) {
                  await this.router.navigate(['/reply', this.replyOrExtraWorkId], {replaceUrl: true});
                } finally {
                  await this.loadingService.stop();
                }
                break;
            }
          }
        } catch (error) {
          console.log(error);
        } finally {
          await this.loadingService.stop();
        }
        break;
      case 'paypal':
        try {
          await this.loadingService.start();

          this.cardFields.submit();
          console.log('Card Fields submit');
        } catch (error) {
          this.cardErrors = error;

          console.log('There was an error with card fields: ', error);
        } finally {
          await this.loadingService.stop();
        }
        break;
      default:
        await this.toastService.error(this.translate.instant('reply.payment.undefinedGateway'));
        break;
    }
  }

  public async approveReply(): Promise<void> {
    try {
      await this.loadingService.start();

      await firstValueFrom(this.extendedClient.replyApprove(+this.replyOrExtraWorkId));
      await this.toastService.success(this.translate.instant('reply.payment.successPay'));
      await this.navigationService.goBack();
    } catch (error) {
      let parsedException = JSON.parse(error.response);
      let message = parsedException.message ?? parsedException.error;
      await this.toastService.error(message);
      await this.router.navigate(
        ['../payment-result', 'fail'],
        {relativeTo: this.activatedRoute, replaceUrl: true}
      );
    } finally {
      await this.loadingService.stop();
    }
  }

  async selectPaymentMethod($event: any): Promise<void> {
    this.pkCode = $event.detail.value;
    await this.createStripeForm();
  }

  createPaypalForm() {
    const style = {
      'input': {
        'font-size': '16px',
        'padding': '12px',
        'line-height': '18px',
      },
    };

    this.cardFields = paypal.CardFields({
      style,
      createOrder: () => {
        return new Promise((resolve) => resolve(this.vm.id));
      },
      onApprove: (data): Promise<void> => {
        switch (this.paymentIntentType) {
          case 1:
            return firstValueFrom(this.extendedClient.paypalAuthorizeOrder(data.orderID))
              .then(async (data) => {
                console.log(data);
                await this.approveReply();
              })
              .catch(async (error): Promise<void> => {
                this.cardErrors = error.result.message;
              });
          case 2:
          case 3:
            return firstValueFrom(this.extendedClient.paypalAuthorizeOrder(data.orderID))
              .then(async (data): Promise<void> => {
                console.log(data);
              })
              .catch(async (error): Promise<void> => {
                this.cardErrors = error.result.message;
              });
          case 4:
            return firstValueFrom(this.extendedClient.paypalCaptureOrder(data.orderID))
              .then(async (data): Promise<void> => {
                console.log(data);

                await firstValueFrom(this.extendedClient.replyGet(this.replyOrExtraWorkId, false))
                  .then(async (data): Promise<void> => {
                    await this.navigationService.goBack();
                  })
                  .catch(async (error): Promise<void> => {
                    await this.router.navigate(['/reply', this.replyOrExtraWorkId], {replaceUrl: true});
                  });
              })
              .catch(async (error): Promise<void> => {
                this.cardErrors = error.result.message;
              });
          case 5:
            return firstValueFrom(this.extendedClient.paypalCaptureOrder(data.orderID))
              .then(async (data): Promise<void> => {
                console.log(data);
                // TODO: make logic for avatar tips
              })
              .catch(async (error): Promise<void> => {
                this.cardErrors = error.result.message;
              });
          default:
            break;
        }
      },
      onError(): void {
        this.cardErrors = 'The requested action could not be performed, semantically incorrect, or failed business validation';
      }
    });

    // Render each field after checking for eligibility
    if (this.cardFields.isEligible()) {
      const nameField = this.cardFields.NameField();
      nameField.render('#card-name-field-container');

      const numberField = this.cardFields.NumberField();
      numberField.render('#card-number-field-container');

      const cvvField = this.cardFields.CVVField();
      cvvField.render('#card-cvv-field-container');

      const expiryField = this.cardFields.ExpiryField();
      expiryField.render('#card-expiry-field-container');
    }

    // const multiCardFieldButton = document.getElementById("card-field-submit-button")

    // multiCardFieldButton.addEventListener("click", () => {
    //   console.log("multiCardFieldButton clicked");

    //   cardFields.submit().then(() => {
    //     console.log("Card Fields submit");
    //   }).catch((error) => {
    //     console.log("There was an error with card fields: ", error);
    //   });
    // });

    // this.cardFields = cardFields;

    // console.log(cardFields);

    // console.log(loadScript);

    // loadScript({
    //   clientId: environment.paypal.clientId,
    //   components: ['buttons']
    // }).then((paypal) => {

    //     paypal
    //       .Buttons({
    //         onApprove: (data, actions) => new Promise(() => {
    //           console.log(data, actions);
    //         }),
    //         createOrder: () => new Promise(() => {
    //           console.log(this.vm.id);
    //           return this.vm.id;
    //         }),
    //         onError(error) {
    //           console.log(error);
    //         },
    //       })
    //       .render("#paypal-button-container")
    //       .catch((error) => {
    //         console.error("failed to render the PayPal Buttons", error);
    //       });

    //     // if (false) {
    //       paypal.HostedFields.render({
    //         createOrder: () => new Promise(() => this.vm.id),
    //         // function () {
    //         //   return fetch('/your-server/paypal/order', {
    //         //     method: 'post'
    //         //   }).then(function(res) {
    //         //     return res.json();
    //         //   }).then(function(orderData) {
    //         //     orderId = orderData.id;
    //         //     return orderId;
    //         //   });
    //         // },
    //         styles: {
    //           '.valid': {
    //             'color': 'green'
    //           },
    //           '.invalid': {
    //             'color': 'red'
    //           }
    //         },
    //         fields: {
    //           number: {
    //             selector: "#card-number-field-container",
    //             placeholder: "4111 1111 1111 1111"
    //           },
    //           cvv: {
    //             selector: "#card-cvv-field-container",
    //             placeholder: "123"
    //           },
    //           expirationDate: {
    //             selector: "#card-expiry-field-container",
    //             placeholder: "MM/YY"
    //           }
    //         }
    //       }).then(function (cardFields) {

    //         console.log(cardFields);


    //         // document.querySelector("#card-form").addEventListener('submit', (event) => {
    //         //   event.preventDefault();
    //         //   cardFields.submit({
    //         //     cardholderName: document.getElementById('card-holder-name').value,
    //         //     billingAddress: {
    //         //       streetAddress: document.getElementById('card-billing-address-street').value,
    //         //       extendedAddress: document.getElementById('card-billing-address-unit').value,
    //         //       region: document.getElementById('card-billing-address-state').value,
    //         //       locality: document.getElementById('card-billing-address-city').value,
    //         //       postalCode: document.getElementById('card-billing-address-zip').value,
    //         //       countryCodeAlpha2: document.getElementById('card-billing-address-country').value
    //         //     }
    //         //   }).then(function () {
    //         //     fetch('/your-server/api/order/' + orderId + '/capture/', {
    //         //       method: 'post'
    //         //     }).then(function(res) {
    //         //       return res.json();
    //         //     }).then(function (orderData) {
    //         //       var errorDetail = Array.isArray(orderData.details) && orderData.details[0];
    //         //       if (errorDetail) {
    //         //         var msg = 'Sorry, your transaction could not be processed.';
    //         //         if (errorDetail.description) msg += '\n' + errorDetail.description;
    //         //         if (orderData.debug_id) msg += ' (' + orderData.debug_id + ')';
    //         //         return alert(msg); // Show a failure message
    //         //       }
    //         //       alert('Transaction completed!');
    //         //     })
    //         //   }).catch(function (error) {
    //         //     alert('Payment could not be captured! ' + JSON.stringify(error))
    //         //   });
    //         // });
    //       });
    //     // } else {
    //       // document.querySelector("#card-form").style = 'display: none';
    //     // }
    // })
    // .catch((error) => {
    //   console.error("failed to load the PayPal JS SDK script", error);
    // });
  }
}
