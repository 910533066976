import { Component, OnInit } from '@angular/core';
import { Client, FileResponse, ReplyResponse } from '../../../api-clients/pyjam/client';
import { AuthService } from '../../../auth/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ReplyStatus } from '../../reply.constants';
import { LoadingService } from '../../../services/loading.service';
import { ActivatedRouteFixService } from '../../../services/activated-route-fix.service';
import { ToastService } from '../../../services/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { ActionSheetController, ActionSheetOptions } from '@ionic/angular';
import { ReportService } from '../../../services/report.service';
import { ReportType } from '../../../report/report.constants';

@Component({
  selector: 'show-result',
  templateUrl: './show-task-result.component.html',
  styleUrls: ['./show-task-result.component.scss'],
})
export class ShowTaskResultComponent implements OnInit {
  private replyId: number;
  public files: FileResponse[] = [];
  public description: string;
  public isTaskOwner: boolean;
  public reply: ReplyResponse;
  private taskResultId: number;

  constructor(
    private activatedRoute: ActivatedRoute,
    private activatedRouteFixService: ActivatedRouteFixService,
    private router: Router,
    private client: Client,
    public authService: AuthService,
    private loadingService: LoadingService,
    private toastService: ToastService,
    private translate: TranslateService,
    private actionSheetController: ActionSheetController,
    private reportService: ReportService,
  ) {
  }

  ngOnInit() {
  }

  ionViewWillEnter() {
    this.loadingService.start().then(() => {
      this.replyId = this.activatedRoute.snapshot.params?.id;
      this.client.replyGet(this.replyId, false).toPromise()
        .then((res) => {
          this.reply = res.data;
          this.client.taskResultShowByTaskId(res.data.task_id).toPromise()
            .then((rspns) => {
              this.description = rspns.data.description;
              this.files = rspns.data.files;
              this.isTaskOwner = rspns.data.user_id != this.authService.parsedToken.userId;
              this.taskResultId = rspns.data.id;
            })
            .catch(async ex => {
              // await this.httpErrorHandlerService.handleHttpException(ex);
            })
            .finally(async () => {
              await this.loadingService.stop();
            });
        });
    });
  }

  public get ReplyStatus(): typeof ReplyStatus {
    return ReplyStatus;
  }

  openFile(file: FileResponse) {
    window.open(file.url, '_blank');
  }

  async onReplyAcceptClick() {
    this.loadingService.start()
      .then(() => {
        this.client.replyAccept(this.replyId).toPromise()
          .then(async () => {
            await this.router.navigate(['../rate-result'], {relativeTo: this.activatedRouteFixService.getActivatedRoute(this.activatedRoute)});
          })
          .catch(async ex => {
            let parsedException = JSON.parse(ex.response);
            let message = parsedException.message ?? parsedException.error;
            if (message == 'transfers_not_allowed') {
              await this.toastService.warning(this.translate.instant('task.details.onboardingErrorsReset'));
            } else {
              // await this.httpErrorHandlerService.handleHttpException(ex);
            }
          });
      })
      .finally(async () => {
        await this.loadingService.stop();
      });
  }

  async onRateButtonClick() {
    await this.router.navigate(['../rate-result'], {relativeTo: this.activatedRouteFixService.getActivatedRoute(this.activatedRoute)});
  }

  async onReplyDisputeClick() {
    await this.router.navigate(['/reply/' + this.replyId + '/dispute-description']);
  }

  async onReplySendResultClick() {
    await this.router.navigate(['/reply/' + this.replyId + '/task-result/files']);
  }

  async onReplyEditResultClick() {
    await this.router.navigate(['/reply/' + this.replyId + '/edit-task-result']);
  }

  async openMenu() {
    const opts: ActionSheetOptions = {
      buttons: [
        {
          text: this.translate.instant('report.type.' + ReportType.TASK_RESULT),
          handler: () => {
            this.reportService.openReportModal(ReportType.TASK_RESULT, this.taskResultId).then();
          }
        }
      ],
    };
    const actionSheet: HTMLIonActionSheetElement = await this.actionSheetController.create(opts);
    await actionSheet.present();
  }
}
