import { AfterViewInit, Component, Inject, NgZone, OnDestroy, OnInit } from '@angular/core';
import {
  Client,
  NotificationIndexResource,
  NotificationsDataResponse,
  QtyUnreadMessages
} from './api-clients/pyjam/client';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router, RouterEvent } from '@angular/router';
import { WebSocketController } from './services/web-socket.controller';
import { AuthService, ParsedUser } from './auth/auth.service';
import { BehaviorSubject, firstValueFrom, Observable } from 'rxjs';
import { ToastService } from './services/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from './services/notification.service';
import { VoipNotificationsService } from './services/voip.notifications.service';
import { App, AppInfo, URLOpenListenerEvent } from '@capacitor/app';
import { BackButtonComponent } from './ui-components/back-button/back-button.component';
import { AppStateChangeService } from './services/app-state-change.service';
import { environment } from 'src/environments/environment';
import { DOCUMENT } from '@angular/common';
import hints from 'src/assets/tips.json';
import Swiper, { EffectFade, Navigation, Pagination } from 'swiper';
import { PlatformService } from './services/platform.service';
import { SubscriptionsBag } from './services/subscriptions-bag';
import { SplashScreen } from '@capacitor/splash-screen';
import { NavigationService } from './services/navigation.service';
import { OnlineStateService } from './services/online-state.service';
import { URL_DOWNLOAD_APP } from './app.constants';
import { ConsoleErrorService } from './console-error/console-error.service';

Swiper.use([Pagination, EffectFade, Navigation]);

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  providers: [BackButtonComponent]
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  public noReadMessages: number = 0;
  public showMenuBehaviorSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public showMenu$: Observable<boolean> = this.showMenuBehaviorSubject.asObservable();
  public requestedUrl: string;
  public availableTips: any[] = [];
  public currentHint: number = null;
  private swiper: Swiper;
  private sb: SubscriptionsBag = new SubscriptionsBag();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    public router: Router,
    public platformService: PlatformService,
    public onlineStateService: OnlineStateService,
    public notificationService: NotificationService,
    private webSocketController: WebSocketController,
    private client: Client,
    private authService: AuthService,
    private toastService: ToastService,
    private translate: TranslateService,
    private navigationService: NavigationService,
    private voipNotificationsService: VoipNotificationsService,
    private appStateChangeService: AppStateChangeService,
    private ngZone: NgZone,
    private consoleErrorService: ConsoleErrorService,
  ) {
    this.addDeepLinksListener().then();
  }

  async ngOnInit(): Promise<void> {
    this.getAppInfo().then();
    const lang: string = this.setDefaultLang();
    this.subscribeEvents(lang);
  }

  ngAfterViewInit(): void {
    SplashScreen.hide().then();

    this.addingScripts();
  }

  ngOnDestroy(): void {
    this.sb.unsubscribeAll();
  }

  private async getAppInfo(): Promise<void> {
    if (this.platformService.isDevice) {
      try {
        const appInfo: AppInfo = await App.getInfo();
        console.log('\x1b[33m' + 'Application version: ' + appInfo.version + ' (build: ' + appInfo.build + ')' + '\x1b[0m');
      } catch (error) {
        console.error(error);
      }
    } else {
      const data = require('../../package.json');
      const appVersion = data.version;
      console.log('\x1b[33m' + 'Web version: ' + appVersion + '\x1b[0m');
    }
  }

  private setDefaultLang(): string {
    const userLang: string = (navigator?.language) ? navigator?.language.split('-')[0] : 'en';
    const lang: string = (environment.activeLanguages.indexOf(userLang) != -1) ? userLang : 'en';
    this.translate.setDefaultLang(lang);

    return lang;
  }

  private subscribeEvents(lang: string): void {
    this.sb.sub = this.authService.parsedUser$.subscribe(async (parsedUser: ParsedUser): Promise<void> => {
      if (this.authService.isAuthenticated) {
        if (parsedUser.lang && parsedUser.lang.locale && environment.activeLanguages.indexOf(parsedUser.lang.locale) != -1) {
          this.translate.use(parsedUser.lang.locale);
        } else {
          this.translate.use(this.translate.defaultLang);
        }

        this.checkUserInfo(parsedUser).then();

        this.loadChatsData().then();
        this.loadNotificationData().then();

        this.notificationService.initializePushNotifications().then();
        if (this.platformService.isDevice && this.platformService.isIos) {
          this.voipNotificationsService.initializeVoipPushNotifications().then();
        }
      } else {
        this.translate.use(lang);
      }
    });

    this.sb.sub = this.appStateChangeService.appStateChange$.subscribe(async (isActive: boolean): Promise<void> => {
      if (this.platformService.isDevice && isActive && this.authService.isAuthenticated) {
        await this.loadChatsData();
        await this.loadNotificationData();
      }
    });

    this.sb.sub = this.router.events.subscribe(async (event: RouterEvent): Promise<void> => {
      if (event instanceof NavigationStart && event.url.indexOf('/login') === -1) {
        // console.log('\x1b[34m' + '[NavigationStart] ' + event.url + '\x1b[0m');

        this.requestedUrl = event.url;
      }

      if (!(event instanceof NavigationEnd)) return;

      let stack: ActivatedRoute[] = [];
      stack.push(this.router.routerState.root);

      let target: ActivatedRoute = null;

      this.showMenuBehaviorSubject.next(parseInt(localStorage.getItem('showMenu')) > 0);

      while (stack.length) {
        let ar: ActivatedRoute = stack.pop();
        stack.push(...ar.children);
        this.showMenuBehaviorSubject.next(ar.snapshot.data.showMenu);
        target = ar;
      }

      if (event.urlAfterRedirects.indexOf('login/') === -1
        && event.urlAfterRedirects.indexOf('registration/') === -1
        && event.urlAfterRedirects.indexOf('.html') === -1) {
        localStorage.setItem('lastURL', event.urlAfterRedirects);
        localStorage.setItem('showMenu', this.showMenuBehaviorSubject.value ? '1' : '0');
      }

      const isDeniedPage: boolean = (this.router.url == '/intro' || this.router.url == '/tutorial-control'
        || this.router.url == '/pyjam-animate'
        || this.router.url == '/support'
        || this.router.url.includes('.html')
      );

      if (!isDeniedPage && !localStorage.getItem('introPassed')) {
        await this.goToIntro();
      } else {
        setTimeout((): void => {
          this.navigationService.checkNewTips.next(true);
        }, 2500);
      }

      if (!isDeniedPage) {
        this.showDownloadPrompt();
      }
    });

    this.sb.sub = this.navigationService.checkNewTips.subscribe((): void => {
      this.showTips();
    });

    this.sb.sub = this.webSocketController.chatEvent.subscribe(res => {
      switch (res.type) {
        case 'qtyUnreadMessages': {
          this.noReadMessages = res.data.done + res.data.connects + res.data.inProgress;
          break;
        }
      }
    });

    this.sb.sub = this.webSocketController.notificationEvent.subscribe(res => {
      switch (res.type) {
        case 'notification': {
          this.notificationService.countNotifications++;
          break;
        }
      }
    });
  }

  private addingScripts(): void {
    //   if (environment.useFbSdk) {
    //     const fbScript: HTMLScriptElement = document.createElement('script');
    //     fbScript.innerHTML = `
    //       !function(f,b,e,v,n,t,s)
    //       {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    //       n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    //       if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    //       n.queue=[];t=b.createElement(e);t.async=!0;
    //       t.src=v;s=b.getElementsByTagName(e)[0];
    //       s.parentNode.insertBefore(t,s)}(window, document,'script',
    //       'https://connect.facebook.net/en_US/fbevents.js');
    //       fbq('init', '` + environment.fbTrId + `');
    //       fbq('track', 'PageView');
    //       `;
    //     document.querySelector('head').appendChild(fbScript);
    //
    //     const fbPixel: HTMLElement = document.createElement('noscript');
    //     const fbPixelImg: HTMLImageElement = document.createElement('img');
    //     fbPixelImg.setAttribute('src', 'https://www.facebook.com/tr?id=' + environment.fbTrId + '&ev=PageView&noscript=1');
    //     fbPixel.appendChild(fbPixelImg);
    //     document.querySelector('head').appendChild(fbPixel);
    //
    //     setTimeout(async () => {
    //       await FacebookLogin.initialize({appId: environment.fbAppId});
    //       // console.log('platforms:', getPlatforms());
    //       if (!this.platformService.isAndroid) {
    //         await FacebookLogin.setAdvertiserTrackingEnabled({enabled: true});
    //         await FacebookLogin.setAdvertiserIDCollectionEnabled({enabled: true});
    //       }
    //     });
    //   }

    //   if (environment.useYm) {
    //     const ymScript: HTMLScriptElement = document.createElement('script');
    //     ymScript.innerHTML = `
    //     (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
    //     m[i].l=1*new Date();
    //     for (var j = 0;
    //     j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
    //     k=e.createElement(t),
    //     a=e.getElementsByTagName(t)[0],
    //     k.async=1,k.src=r,
    //     a.parentNode.insertBefore(k,a)})
    //     (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");
    //
    //     ym(` + environment.ymId + `, "init", {
    //         clickmap:true,
    //         trackLinks:true,
    //         accurateTrackBounce:true,
    //         webvisor:true
    //     });`;
    //     document.querySelector('head').appendChild(ymScript);
    //
    //     const ymPixel = document.createElement('noscript');
    //     const ymPixelImg = document.createElement('img');
    //     ymPixelImg.setAttribute('src', 'https://mc.yandex.ru/watch/' + environment.ymId);
    //     ymPixelImg.setAttribute('style', 'position:absolute; left:-9999px;');
    //     ymPixel.appendChild(ymPixelImg);
    //     document.querySelector('head').appendChild(ymPixel);
    //   }

    if (environment.useSmartlook) {
      const script: HTMLScriptElement = document.createElement('script');
      script.innerHTML = `
        window.smartlook||(function(d) {
          var o=smartlook=function(){ o.api.push(arguments)},
          h=d.getElementsByTagName('head')[0];
          var c=d.createElement('script');
          o.api=new Array();c.async=true;
          c.type='text/javascript';
          c.charset='utf-8';
          c.src='https://web-sdk.smartlook.com/recorder.js';
          h.appendChild(c);
          })(document);
          smartlook('init', '${environment.smartlookApiKey}', { region: 'eu' });
      `;

      document.head.appendChild(script);
    }
  }

  private async checkUserInfo(user: ParsedUser): Promise<void> {
    if (user.name === null || user.surname === null) {
      await this.router.navigate(['registration/name']);
    }
  }

  private async goToIntro(): Promise<void> {
    // await this.router.navigateByUrl('/intro', {replaceUrl: true});
    await this.router.navigateByUrl('/tutorial-control', {replaceUrl: true});
  }

  //#region Load data
  public async loadNotificationData(): Promise<void> {
    try {
      const data: NotificationsDataResponse = await firstValueFrom(this.client.notificationIndex(1));
      this.notificationService.countNotifications = data.data.filter((el: NotificationIndexResource) => !el.is_read).length;
    } catch (error) {
      await this.toastService.error(this.translate.instant('notifications.simpleError') + error);
    }
  }

  private async loadChatsData(): Promise<void> {
    try {
      const data: QtyUnreadMessages = await firstValueFrom(this.client.chatQtyUnreadMessages());
      this.noReadMessages = data.done + data.connects + data.inProgress;
    } catch (error) {
      console.error(error);
    }
  }

  //#endregion Load data

  //#region Download apps
  private showDownloadPrompt(): void {
    const lastPrompt = localStorage.getItem('lastPrompt');
    const popupEl = this.document.getElementById('downloadPrompt');
    const now = new Date().getTime();
    const twentyDays = 20 * 24 * 60 * 60 * 1000; // 20 days in milliseconds

    // Show prompt if it hasn't been shown in the last 20 days
    if ((this.platformService.isAndroid || this.platformService.isIos)
      && (!this.platformService.isCordova && !this.platformService.isCapacitor)
      && (!lastPrompt || now - Number(lastPrompt) > twentyDays)) {
      popupEl.style.display = 'block';

      const prompt = this.document.querySelector('p');
      if (this.platformService.isAndroid) {
        prompt.textContent = 'Visit the Google Play to download our app!';
      } else if (this.platformService.isIos) {
        prompt.textContent = 'Visit the App Store to download our app!';
      }
    }
  }

  public hideDownloadPrompt(): void {
    const now: number = new Date().getTime();
    localStorage.setItem('lastPrompt', now.toString());
    const popupEl: HTMLElement = this.document.getElementById('downloadPrompt');

    if (popupEl) {
      popupEl.style.display = 'none';
    }
  }

  public downloadApp(): void {
    if (this.platformService.isAndroid) {
      this.document.location.href = URL_DOWNLOAD_APP.ANDROID;
    } else if (this.platformService.isIos) {
      this.document.location.href = URL_DOWNLOAD_APP.IOS;
    }
  }

  //#endregion Download apps

  //#region Tips
  private initializeSwiper(): void {
    const tip = this.availableTips[this.currentHint];

    if (!tip) return;

    const hintEl: HTMLElement = this.document.querySelector('.hint');
    AppComponent.updateHint(hintEl, tip);

    this.swiper = new Swiper(
      '.tips-swiper',
      {
        loop: false,
        initialSlide: 0,
        navigation: {
          nextEl: '.swiper-button-next,.next-tip',
          prevEl: '.swiper-button-prev',
        },
        effect: 'fade',
        fadeEffect: {
          crossFade: true
        },
        pagination: {
          el: '.swiper-pagination.tips-pagination',
          clickable: true,
          dynamicBullets: false,
        },
        on: {
          transitionStart: (swiper: Swiper): void => {
            if (swiper?.wrapperEl) {
              swiper.wrapperEl.setAttribute('current', swiper.activeIndex + '');
            }
          },
          realIndexChange: (swiper: Swiper): void => {
            const currentIndex: number = parseInt(swiper.wrapperEl.getAttribute('current'), 10);

            if (swiper?.activeIndex !== currentIndex) {
              this.ngZone.run((): void => {
                this.currentHint = swiper.activeIndex;
                const tip = window['availableTips'][swiper.activeIndex];
                const hintEl: HTMLElement = this.document.querySelector('.hint');

                AppComponent.updateHint(hintEl, tip);
              });
            }
          }
        },
      });
  }

  private showTips(): void {
    setTimeout(() => {
      const passedTips: string[] = (localStorage.getItem('passedTips'))
        ? localStorage.getItem('passedTips').split(',')
        : [];

      this.availableTips = hints.tips.filter(tip => {
        const target: Element = this.document.querySelector(tip.element);
        const targetRect: DOMRect = target?.getBoundingClientRect();
        return (targetRect?.width && targetRect?.height && (passedTips.indexOf(tip.name) == -1));
      });
      window['availableTips'] = this.availableTips;
      this.currentHint = 0;

      if (this.availableTips.length > 0) {
        this.initializeSwiper();
        if (this.swiper.slides.length == 0) {
          this.showTips();
        }
      }
    }, 200);
  }

  public onCloseHintClick(): void {
    this.currentHint = null;
    AppComponent.hideHintHighlights();
  }

  static updateHint(hintEl, tip, attempt = 0): void {
    const targetEl = document.querySelector(tip.element);
    if (!hintEl || !targetEl) {
      console.error('No Hint or Target element!', tip);
      if (attempt < 3) {
        setTimeout((): void => {
          console.log('Try updateHint again!');
          const hintEl: HTMLElement = document.querySelector('.hint');
          this.updateHint(hintEl, tip, attempt + 1);
        }, 2000);
      }
      return;
    }

    hintEl.classList.remove('left-arrow');
    hintEl.classList.remove('right-arrow');
    hintEl.classList.remove('top-arrow');
    hintEl.classList.remove('bottom-arrow');

    const pageRect: DOMRect = document.querySelector('ion-app.ion-page').getBoundingClientRect();
    const hintRect = hintEl.getBoundingClientRect();
    const targetRect = targetEl.getBoundingClientRect();
    const targetLeft: number = targetRect.left - pageRect.left;
    const targetTop: number = targetRect.top;
    const targetX: number = targetLeft + targetRect.width / 2;
    const targetY: number = targetRect.top + targetRect.height / 2;
    const targetWidth: number = targetRect.width;
    const targetHeight: number = targetRect.height;
    const targetCenterDistX: number = Math.abs(targetX - pageRect.width / 2);
    const targetCenterDistY: number = Math.abs(targetY - window.innerHeight / 2);
    const borderDist: number = 10;
    let left: number = 0;
    let top: number = 0;

    if (targetCenterDistY > targetCenterDistX && targetY < window.innerHeight / 2) {
      left = targetX - hintRect.width / 2;
      top = targetTop + targetHeight + 15;
    } else if (targetCenterDistY > targetCenterDistX && targetY > window.innerHeight / 2) {
      left = targetX - hintRect.width / 2;
      top = targetTop - hintRect.height - 15;
    } else if (targetCenterDistY < targetCenterDistX && targetX < pageRect.width / 2) {
      left = targetLeft + targetWidth + 15;
      top = targetTop + targetHeight / 2 - hintRect.height / 2;
    } else {
      left = targetLeft - hintRect.width - 15;
      top = targetTop + targetHeight / 2 - hintRect.height / 2;
    }

    if (window.innerHeight - top - hintRect.height < borderDist) {
      top = window.innerHeight - hintRect.height - borderDist;
    }

    if (pageRect.width - left - hintRect.width < borderDist) {
      left = pageRect.width - hintRect.width - borderDist;
    }

    if (top < borderDist) {
      top = borderDist;
    }

    if (left < borderDist) {
      left = borderDist;
    }

    hintEl.style.left = left + 'px' || '';
    hintEl.style.top = top + 'px' || '';

    AppComponent.hideHintHighlights();
    targetEl.classList.add('hint-highlight');

    if (tip.round) {
      targetEl.style.borderRadius = tip.round;
    }

    setTimeout((): void => {
      const targetRect = targetEl.getBoundingClientRect();
      const targetX = targetRect.left + targetRect.width / 2;
      const targetY = targetRect.top + targetRect.height / 2;
      const hintRect = hintEl.getBoundingClientRect();

      if (targetY < hintRect.top && targetX > hintRect.left && targetX < hintRect.left + hintRect.width) {
        hintEl.classList.add('top-arrow');
        hintEl.style.setProperty('--hint-arrow-offset', (targetX - hintRect.left - 15) + 'px');
      } else if (targetX < hintRect.left && targetY > hintRect.top && targetY < hintRect.top + hintRect.height) {
        hintEl.classList.add('left-arrow');
        hintEl.style.setProperty('--hint-arrow-offset', (targetY - hintRect.top - 15) + 'px');
      } else if ((targetX > hintRect.left + hintRect.width) && targetY > hintRect.top && targetY < hintRect.top + hintRect.height) {
        hintEl.classList.add('right-arrow');
        hintEl.style.setProperty('--hint-arrow-offset', (targetY - hintRect.top - 15) + 'px');
      } else if ((targetY > hintRect.top + hintRect.height) && targetX > hintRect.left && targetX < hintRect.left + hintRect.width) {
        hintEl.classList.add('bottom-arrow');
        hintEl.style.setProperty('--hint-arrow-offset', (targetX - hintRect.left - 15) + 'px');
      }

      hintEl.style.opacity = 1;
      const storagePassedTips: string = localStorage.getItem('passedTips');
      const passedTips: string[] = (storagePassedTips) ? storagePassedTips.split(',') : [];

      if (passedTips.find((tipName: string): boolean => tipName == tip.name) === undefined) {
        passedTips.push(tip.name);
        localStorage.setItem('passedTips', passedTips.join(','));
      }
    });
  }

  static hideHintHighlights(): void {
    const highlightedElems: NodeListOf<Element> = document.querySelectorAll('.hint-highlight');
    highlightedElems.forEach((element: HTMLElement): void => {
      element.classList.remove('hint-highlight');
      element.style.borderRadius = undefined;
    });
  }

  //#endregion Tips

  public async openConsoleModal(): Promise<void> {
    await this.consoleErrorService.openConsoleModal();
  }

  private async addDeepLinksListener(): Promise<void> {
    await App.addListener('appUrlOpen', (event: URLOpenListenerEvent): void => {
      this.ngZone.run((): void => {
        // Example url: https://app.pyjam.com/support
        // slug = /support
        const slug: string = event.url.split('.com').pop();
        if (slug) {
          console.log('App opened with URL:', event);
          this.router.navigateByUrl(slug);
        } else {
          console.warn('No slug in URL:', event);
        }
      });
    });
  }
}
